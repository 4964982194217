
function Nav() {
  return (
    <nav id="TopBar">
      <a href="#" className="logo" alt="Home"></a>
      <ul class="SubNav">
        <li><a href="https://errric.darkroom.com/" className="nav-btn nav-btn-1" target="blank">Photos</a></li>
        <li><a className="nav-btn nav-btn-2" >About me</a></li>
      </ul>
    </nav>
  );
}

export default Nav;
